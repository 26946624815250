import { useRouter } from "next/router";
import { useMemo } from "react";
import type { MenuItemType } from "core/entities";
import { useCentrixApi } from "core/hooks/useCentrixApi";
import { EMPTY_VALUE } from "config";
import { useDmsCategoryLibraryProcessor } from "features/DMS/hooks/useDmsCategoryLibraryProcessor";
import { CategoryMenuItem, MenuLink, RootMenuItem } from "./defs";

export const URL_UNDEFINED_CHARACTER = "#";

/**
 * @param menuItemType defaults to "Header"
 */
export function useMobileMenuItems(menuItemType: MenuItemType = "Header") {
  const { locale } = useRouter();
  const processLibrary = useDmsCategoryLibraryProcessor();

  const { data, isLoading, isError } = useCentrixApi({
    path: "/api/app/menu-item",
    parameters: {
      query: {
        locale,
      },
    },
    swrOptions: {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  });

  const menuItems: RootMenuItem[] = useMemo(() => {
    if (!data || !Array.isArray(data)) {
      return [];
    }
    const rootItems: RootMenuItem[] = data
      .filter((item) => {
        const isCorrectType = item.menuItemType === menuItemType;
        const shouldDisplay =
          item.menuItemType !== "Header" ||
          (Array.isArray(item.libraries) && item.libraries.length > 0) ||
          (Array.isArray(item?.submenus) && item.submenus.length > 0) ||
          item.url !== "#";
        return isCorrectType && shouldDisplay;
      })
      .map((rootItem) => {
        const libraries: RootMenuItem["libraries"] =
          rootItem.libraries
            ?.map(processLibrary)
            .map((entry) => ({
              id: entry.id,
              displayName: entry.currentTranslation?.name,
            }))
            .filter(
              (entry): entry is RootMenuItem["libraries"][number] =>
                typeof entry.id === "string" &&
                typeof entry.displayName === "string"
            ) || [];
        const categories: RootMenuItem["categories"] =
          rootItem.submenus
            ?.map((entry) => {
              const links: RootMenuItem["categories"][number]["links"] =
                entry.submenus
                  ?.map((link) => ({
                    id: link.id!,
                    displayName: link.displayName ?? EMPTY_VALUE,
                    url: link.url,
                    target: link.target,
                  }))
                  .filter(
                    (linkEntry): linkEntry is MenuLink =>
                      typeof entry.id === "string"
                  ) || [];
              return {
                id: entry.id,
                displayName: entry.displayName,
                url:
                  entry.url === URL_UNDEFINED_CHARACTER ? undefined : entry.url,
                links,
                target: entry.target,
              };
            })
            .filter(
              (entry): entry is CategoryMenuItem =>
                typeof entry.id === "string" &&
                ((entry.links?.length ?? 0) > 0 || Boolean(entry.url))
            ) || [];
        return {
          id: rootItem.id!,
          libraries,
          displayName: rootItem.displayName ?? EMPTY_VALUE,
          target: rootItem.target,
          url:
            rootItem.url === URL_UNDEFINED_CHARACTER ? undefined : rootItem.url,
          categories,
        };
      });
    return rootItems;
  }, [data, menuItemType, processLibrary]);

  return { isLoading, isError, data, menuItems };
}
