import "mock-service-worker/initMSW";
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from "@microsoft/applicationinsights-react-js";
import { ApplicationErrorBoundaryPage } from "core/pages/ApplicationErrorBoundaryPage";
import { reactPlugin } from "appInsightsWeb";
import React, { useRef, useEffect, useState, Suspense } from "react";
import Head from "next/head";
import App, { AppContext, AppInitialProps, AppProps } from "next/app";
import { GlobalLoader } from "core/components/GlobalLoader";
import { Layout } from "core/components/Layout";
import Authorize from "core/components/Authorize";
import { ExtensoTokenChecker } from "core/components/ExtensoTokenChecker";
import { PagePermissionChecker } from "core/components/PagePermissionChecker";
import { PageErrorBoundary } from "core/components/PageErrorBoundary";
import { LayoutBypass } from "core/components/LayoutBypass";
import { MUIThemeAndLocalizationProvider } from "core/components/MUIThemeAndLocalizationProvider";
import { ConfigCheckWrapper } from "core/components/ConfigCheckWrapper";
import { DatePickerLocalizationProvider } from "core/components/DatePickerLocalizationProvider";
import ExtensoTokenProvider from "core/context/ExtensoContext";
import { LicenseInfo } from "@mui/x-license";
import CssBaseline from "@mui/material/CssBaseline";
import {
  MUI_X_LICENSE,
  REVALIDATE_IF_STALE,
  validateConfigServerSide,
} from "config";
import { SWRConfig } from "swr";
import type { BrandCode, RegionCode } from "core/entities";
import { ApplicationLoadingDisplay } from "core/components/ApplicationLoadingDisplay";
import { AvailableRegionsContextProvider } from "core/context/AvailableRegionsContext";
import { ApplicationConfigurationErrorPage } from "core/pages/ApplicationConfigurationErrorPage";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { GlobalStylesComponent } from "core/components/GlobalStylesComponent";
import { RouterLocale } from "core/components/RouterLocale";
import { Toaster } from "core/components/Toaster";
import { usePostScrollHeightToParent } from "core/hooks/usePostScrollHeightToParent";
import { useRouter } from "next/router";
import { Box, ThemeOptions } from "@mui/material";
import { CustomThemePicker } from "core/pages/LandingPage/CustomThemePicker";
import { AvailableRoutes } from "resources/availableRoutes";
import { NuqsAdapter } from "nuqs/adapters/next/pages";
import { PublicApp } from "../core/components/PublicApp";

// Assert invariants
validateConfigServerSide();

// https://mui.com/customization/how-to-customize/
// recommends hoisting to static variable to avoid rerendering
// the generated <style> tag
const GlobalStyles = <GlobalStylesComponent />;
const Baseline = <CssBaseline />;

// https://mui.com/components/data-grid/getting-started/#license-key-installation
LicenseInfo.setLicenseKey(MUI_X_LICENSE);

function SharedWrapper({
  children,
  brandCode,
  availableRegions = [],
  customTheme,
}: {
  children: React.ReactNode;
  brandCode?: BrandCode | undefined | null;
  availableRegions?: RegionCode[];
  customTheme?: ThemeOptions | null;
}) {
  return (
    <Suspense
      // Suspense is triggered when loading a translation resource
      fallback={
        <MUIThemeAndLocalizationProvider brandCode={brandCode || "fixaut"}>
          <ApplicationLoadingDisplay />
        </MUIThemeAndLocalizationProvider>
      }
    >
      {GlobalStyles}
      {Baseline}
      {brandCode && (
        <Head>
          <link rel="icon" href={`/favicons/${brandCode}.png`} />
        </Head>
      )}
      <NuqsAdapter>
        <AvailableRegionsContextProvider availableRegions={availableRegions}>
          <ConfigCheckWrapper>
            <AppInsightsContext.Provider value={reactPlugin}>
              <AppInsightsErrorBoundary
                appInsights={reactPlugin}
                onError={ApplicationErrorBoundaryPage}
              >
                <MUIThemeAndLocalizationProvider
                  brandCode={brandCode ?? "fixaut"}
                  customTheme={customTheme}
                >
                  <DatePickerLocalizationProvider>
                    <Toaster>
                      <Box
                        sx={{
                          backgroundColor: "background.default",
                          minHeight: "100vh",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {children}
                      </Box>
                    </Toaster>
                  </DatePickerLocalizationProvider>
                </MUIThemeAndLocalizationProvider>
              </AppInsightsErrorBoundary>
            </AppInsightsContext.Provider>
          </ConfigCheckWrapper>
        </AvailableRegionsContextProvider>
      </NuqsAdapter>
    </Suspense>
  );
}

type PitstopConfigProps = {
  config:
    | {
        brandCode: BrandCode;
        availableRegions: RegionCode[];
      }
    | { error: { message: string } };
};

type PitstopAppProps = AppProps & PitstopConfigProps;

export default function PitstopApp({
  Component,
  pageProps,
  config,
}: PitstopAppProps) {
  const {
    pathname,
    query: { customizeTheme },
  } = useRouter();

  const [customTheme, setCustomTheme] = useState<ThemeOptions | null>(null);

  const initialConfigRef = useRef(config);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  usePostScrollHeightToParent();

  useEffect(() => {
    setIsInitialLoad(false);
  }, []);

  const currentBrandCode =
    "brandCode" in initialConfigRef.current
      ? initialConfigRef.current.brandCode
      : null;

  if (isInitialLoad) {
    return (
      <SharedWrapper brandCode={currentBrandCode}>
        <ApplicationLoadingDisplay />
      </SharedWrapper>
    );
  }

  if ("error" in initialConfigRef.current) {
    reactPlugin.trackException({
      severityLevel: SeverityLevel.Error,
      properties: { component: "_app", config: initialConfigRef.current },
    });
    return (
      <SharedWrapper brandCode={currentBrandCode}>
        <ApplicationConfigurationErrorPage
          message={initialConfigRef.current.error.message}
        />
      </SharedWrapper>
    );
  }

  const { availableRegions } = initialConfigRef.current;

  /**
   * If the page is public, we don't need to render the full application
   * and can skip the loading screen and other components.
   */
  const isPublic = pathname.split("/").at(1) === "public";
  if (isPublic) {
    // Error route is a special case that will be displayed in an IFrame
    // so we render it outside of the PublicApp component to avoid displaying
    // the layout and other components
    const errorRoute: AvailableRoutes = "/public/error-code";
    if (pathname === errorRoute) {
      return (
        <SharedWrapper
          brandCode={currentBrandCode}
          availableRegions={availableRegions}
        >
          <Component {...pageProps} />
        </SharedWrapper>
      );
    }
    return (
      <SharedWrapper
        brandCode={currentBrandCode}
        availableRegions={availableRegions}
      >
        <PublicApp Component={Component} pageProps={pageProps} />
      </SharedWrapper>
    );
  }

  return (
    <SWRConfig
      value={{
        revalidateOnFocus: false,
        revalidateIfStale: REVALIDATE_IF_STALE,
        errorRetryCount: 5,
      }}
    >
      <ExtensoTokenProvider>
        {customizeTheme === "true" && (
          <CustomThemePicker setCustomTheme={setCustomTheme} />
        )}
        <SharedWrapper
          brandCode={currentBrandCode}
          customTheme={customTheme}
          availableRegions={availableRegions}
        >
          <Authorize>
            <RouterLocale />
            <LayoutBypass Component={Component} pageProps={pageProps}>
              <GlobalLoader>
                <ExtensoTokenChecker>
                  <Layout>
                    <PagePermissionChecker>
                      <PageErrorBoundary>
                        <Component {...pageProps} />
                      </PageErrorBoundary>
                    </PagePermissionChecker>
                  </Layout>
                </ExtensoTokenChecker>
              </GlobalLoader>
            </LayoutBypass>
          </Authorize>
        </SharedWrapper>
      </ExtensoTokenProvider>
    </SWRConfig>
  );
}

PitstopApp.getInitialProps = async (
  context: AppContext
): Promise<AppInitialProps & PitstopConfigProps> => {
  // https://nextjs.org/docs/advanced-features/custom-app
  const appProps = await App.getInitialProps(context);
  const host = context.ctx.req?.headers?.host;
  if (host) {
    const { getPitstopConfig } = await import("core/utils/getPitstopConfig");
    const config = await getPitstopConfig(host);
    return { ...appProps, config };
  }
  return {
    ...appProps,
    config: { error: { message: "client side getInitialProps call" } },
  };
};
