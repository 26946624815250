import { useRouter } from "next/router";
import { createContext, useContext, useMemo } from "react";
import { useRegion } from "./useRegion";

export const LocaleOverrideContext = createContext<string | undefined>(
  undefined
);

/**
 * @returns an array of the current locale, the default locale, and the fallback locale for use with the Intl formatter
 * @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#locales_argument
 */
export function useIntlFormatterLocales() {
  const overrideLocale = useContext(LocaleOverrideContext);
  const { locale, defaultLocale } = useRouter();
  const region = useRegion();
  return useMemo(
    () =>
      getValidLocales([
        overrideLocale,
        `${locale}-${region.toUpperCase()}`,
        locale,
        defaultLocale,
      ]),
    [overrideLocale, locale, region, defaultLocale]
  );
}

export function getValidLocales(locales: (string | undefined)[]): string[] {
  return locales
    .flatMap((locale) => {
      if (!locale) {
        return ""; // this will be filtered out
      }
      try {
        /**
         * @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/getCanonicalLocales
         */
        return Intl.getCanonicalLocales([locale]); // this function will throw an error if the locale is invalid.
      } catch {
        return ""; // this will be filtered out
      }
    })
    .filter(Boolean);
}
