import { API_URL } from "config";
import { paths } from "resources/api-schema";
import { ExtractParameters, HttpRequestMethod } from "core/entities/APITypes";
import { templateRoute } from "./templateRoute";

/**
 * Builds a URL based on the provided route and parameters.
 * @param route - a route defined in `api-schema.d.ts` to be appended to the base URL. Can contain placeholders.
 * @param parameters - The parameters to be included in the URL. parameters are defined in `api-schema.d.ts` Can include path and query parameters.
 * @returns The constructed URL string.
 * @example
 * ```typescript
 *  const url = buildUrl({
 *    route: "/api/person/{id}/group/{groupId}",
 *    parameters: {
 *      path: { id: "123", groupId: "456" },
 *      query: { filter: "active" },
 *    },
 *  });
 *  console.log(url) //"https://api.example.com/api/person/123/group/456?filter=active"
 * ```
 */
export function buildCentrixUrl<
  T extends keyof paths,
  M extends HttpRequestMethod = "get",
>({
  path: originalPath,
  parameters,
  locale,
}: {
  path: T;
  parameters: ExtractParameters<T, M> | undefined;
  locale?: string;
}) {
  let path: string = originalPath;
  const searchParams = new URLSearchParams();
  // Not setting via headers as the headers are not used by SWR for the storage key
  // https://aspnetboilerplate.com/Pages/Documents/Localization#how-the-current-language-is-determined
  if (locale) {
    searchParams.set("culture", locale);
    searchParams.set("ui-culture", locale);
  }

  // if no parameters are provided, return the URL with the locale
  if (!parameters || typeof parameters !== "object") {
    const url = new URL(path, API_URL);
    url.search = searchParams.toString();
    return url.toString();
  }

  // replace path parameters
  if (
    "path" in parameters &&
    typeof parameters.path === "object" &&
    parameters.path
  ) {
    path = templateRoute(path, parameters.path as Record<string, string>);
  }

  // add query parameters
  if ("query" in parameters && parameters.query) {
    Object.entries(parameters.query)
      .sort((a, b) => (a[0] > b[0] ? 1 : -1))
      .forEach(([key, value]) => {
        if (value === undefined) {
          return;
        }
        if (Array.isArray(value)) {
          value.forEach((v) => searchParams.append(key, v));
          return;
        }
        searchParams.append(key, value);
      });
  }

  const url = new URL(path, API_URL);
  url.search = searchParams.toString();
  return url.toString();
}
