import { PageHeader } from "core/components/PageHeader";
import { ReactHookFormSelect } from "core/components/ReactHookFormComponents/ReactHookFormSelect";
import { ReactHookTextField } from "core/components/ReactHookFormComponents/ReactHookTextField";
import { ReactHookEmailInput } from "core/components/ReactHookFormComponents/ReactHookEmailInput";
import { SubmitButton } from "core/components/SubmitButton";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { FieldErrorsImpl, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { regionCodes as configRegionCodes } from "config";
import { useRegion } from "core/hooks/useRegion";
import { useToaster } from "core/hooks/useToaster";
import { useValidationErrorToaster } from "core/hooks/useValidationErrorToaster";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { useRouter } from "next/router";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import { FavoriteButton } from "features/MyLinks/components";
import { PageContainer } from "core/components/PageContainer";
import { useCentrixFetch } from "core/hooks/useCentrixFetch";
import { CancelButton } from "core/components/CancelButton";
import { ResetButton } from "core/components/ResetButton";

const regionCodes = [...configRegionCodes];

const defaultValues = {
  professionalEmail: "",
  personalEmail: "",
  firstName: "",
  lastName: "",
  jobTitle: "",
  operationType: "",
  companyName: "",
  telephoneNumber: "",
  city: "",
  province: "",
  country: "",
  comment: "",
};

export function EmailRequestPage() {
  const { back } = useRouter();
  const centrixFetch = useCentrixFetch();
  const { successToast, errorToast } = useToaster();
  const validationErrorToaster = useValidationErrorToaster();
  const formMethods = useForm({ defaultValues });
  const { isSubmitting } = formMethods.formState;
  const region = useRegion();
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:EmailRequest",
  });
  const { t: tBase } = useTranslation();
  const errorMessage = t("submitError");
  const successMessage = t("submitSuccess");

  async function onValidSubmit(form: typeof defaultValues) {
    const emailRequestResponse = await centrixFetch({
      method: "post",
      path: "/api/app/email-requests",
      body: form,
    });
    if (!emailRequestResponse.ok) {
      errorToast(errorMessage);
      return;
    }
    successToast(successMessage);
    formMethods.reset(defaultValues);
  }

  function onInvalidSubmit(errors: FieldErrorsImpl<typeof defaultValues>) {
    validationErrorToaster(Object.keys(errors).map((name) => t(name)));
  }
  const operationTypeOptions = [
    {
      label: t("operationType.shop"),
      value: t("operationType.shop"),
    },
    { label: t("operationType.ho"), value: t("operationType.ho") },
    {
      label: t("operationType.other"),
      value: t("operationType.other"),
    },
  ];
  const countryOptions = regionCodes
    .sort((a) => (a === region ? -1 : 1))
    .map((code) => ({
      value: tBase(`Region:RegionName.${code}`),
      label: tBase(`Region:RegionName.${code}`),
    }));
  return (
    <PageContainer maxWidth="md">
      <PageHeader
        title={t("pageTitle")}
        userGuideHref="https://app.tango.us/app/workflow/Submitting-an-Email-Request-9d7d6dd79a614387b3fa1e1a01aa2eee"
        TitleSlot={
          <FavoriteButton
            itemId="email-request"
            itemType="Page"
            title={t("pageTitle")}
          />
        }
        RightSlot={
          <Button startIcon={<ChevronLeft />} variant="outlined" onClick={back}>
            {t("goBack")}
          </Button>
        }
      />
      <FormProvider {...formMethods}>
        <Stack
          gap={2}
          component="form"
          onSubmit={formMethods.handleSubmit(onValidSubmit, onInvalidSubmit)}
        >
          <ReactHookEmailInput
            required
            name="professionalEmail"
            label={t("professionalEmail")}
          />
          <ReactHookTextField
            name="firstName"
            label={t("firstName")}
            required
          />
          <ReactHookTextField name="lastName" label={t("lastName")} required />
          <ReactHookTextField name="jobTitle" label={t("jobTitle")} required />

          <ReactHookFormSelect
            required
            name="operationType"
            label={t("operationType")}
            options={operationTypeOptions}
          />
          <ReactHookTextField
            name="companyName"
            label={t("companyName")}
            required
          />
          <ReactHookTextField required name="city" label={t("city")} />
          <ReactHookTextField required name="province" label={t("province")} />
          <ReactHookFormSelect
            required
            name="country"
            label={t("country")}
            options={countryOptions}
          />

          <ReactHookEmailInput
            name="personalEmail"
            required
            TextFieldProps={{
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <Tooltip title={t("personalEmailTooltipTitle")}>
                      <InfoOutlined fontSize="small" />
                    </Tooltip>
                  </InputAdornment>
                ),
              },
            }}
            label={t("personalEmail")}
          />
          <ReactHookTextField
            name="telephoneNumber"
            label={t("telephoneNumber")}
            required
          />
          <ReactHookTextField
            name="comment"
            label={t("comment")}
            multiline
            minRows={6}
            maxRows={6}
          />
          <Stack direction="row" gap={2} justifyContent="flex-end">
            <CancelButton onClick={back} />
            <ResetButton
              onClick={() => {
                formMethods.reset(defaultValues);
              }}
            />
            <SubmitButton loading={isSubmitting} />
          </Stack>
        </Stack>
      </FormProvider>
    </PageContainer>
  );
}
